<template>
  <vs-card class="mt-base">
    <div slot="header">
      <p style="cursor: pointer" @click="goBackToList">
        <feather-icon
          icon="ChevronLeftIcon"
          svgClasses="h-4 w-4"
          class="mr-2 cursor-pointer"
        ></feather-icon>
        {{ userDetail.userType === "doctor" ? "Doctor" : "Nurse" }} List
      </p>
    </div>

    <vs-row
      v-if="
        userDetail.userType === 'doctor' &&
          ['superAdmin', 'admin'].includes($store.state.AppActiveUser.userType)
      "
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="doctorType">Type</label>
          <p id="doctorType">{{ userDetail.doctorType }}</p>
        </div>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="profileImage">Profile Image</label>
          <br />
          <a
            id="profileImage"
            v-if="userDetail.profileImage"
            :href="userDetail.profileImage"
            target="_blank"
          >
            <div class="img-wrapper">
              <img :src="userDetail.profileImage" class="img-fluid" alt />
            </div>
            <!-- <vs-avatar size="100px" :src="userDetail.profileImage" id="profileImage" /> -->
          </a>
          <vs-avatar size="110px" :src="defaultPP" v-else />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'doctor'"
      >
        <div class="w-full m-5">
          <label for="signature">Signature</label>
          <br />
          <a
            id="signature"
            v-if="userDetail.signature"
            :href="userDetail.signature"
            target="_blank"
          >
            <div class="img-wrapper">
              <img :src="userDetail.signature" class="img-fluid" alt />
            </div>
            <!-- <vs-avatar
              size="100px"
              :src="userDetail.signature"
              id="signature"
              v-if="userDetail.signature"
            />-->
          </a>
          <p v-else id="signature">N/A</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="profileImage">AHPRA Certificate</label>
          <br />
          <p v-if="!userDetail.ahpraCertificate" id="ahpraCertificate">N/A</p>
          <a
            id="ahpraCertificate"
            v-else-if="determineFileType(userDetail.ahpraCertificate) !== 'pdf'"
            :href="userDetail.ahpraCertificate"
            target="_blank"
          >
            <img :src="userDetail.ahpraCertificate" class="img-fluid" alt />
            <!-- <vs-avatar id="ahpraCertificate" size="110px" :src="userDetail.ahpraCertificate" /> -->
          </a>
          <a
            id="ahpraCertificate"
            v-else
            :href="userDetail.ahpraCertificate"
            target="_blank"
          >
            <span>Click Here To View</span>
          </a>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="insuranceCertificate">Insurance Certificate</label>
          <br />
          <p v-if="!userDetail.insuranceCertificate" id="insuranceCertificate">
            N/A
          </p>
          <a
            id="insuranceCertificate"
            v-else-if="
              determineFileType(userDetail.insuranceCertificate) !== 'pdf'
            "
            :href="userDetail.insuranceCertificate"
            target="_blank"
          >
              <img
                :src="userDetail.insuranceCertificate"
                class="img-fluid"
                alt
              />
            <!-- <vs-avatar
              id="insuranceCertificate"
              size="110px"
              :src="userDetail.insuranceCertificate"
            />-->
          </a>
          <a
            id="insuranceCertificate"
            v-else
            :href="userDetail.insuranceCertificate"
            target="_blank"
          >
            <span>Click here to view</span>
          </a>
        </div>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <p id="firstName">{{ userDetail.firstName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <p id="lastName">{{ userDetail.lastName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <div style="display: flex">
            <p id="email" style="margin-right: 5px">{{ userDetail.email }}</p>
            <vx-tooltip
              v-if="userDetail.emailVerifiedAt"
              text="Verified"
              style="display: flex"
            >
              <feather-icon
                style="color: rgba(var(--vs-primary), 1) !important"
                icon="CheckCircleIcon"
                svgClasses="h-5 w-5"
                class="mr-2 cursor-pointer"
              ></feather-icon>
            </vx-tooltip>
            <vx-tooltip v-else text="Unverified" style="display: flex">
              <feather-icon
                style="color: rgba(var(--vs-danger), 1) !important"
                icon="AlertCircleIcon"
                svgClasses="h-5 w-5"
                class="mr-2 cursor-pointer"
              ></feather-icon>
            </vx-tooltip>
            <VerifyEmail
              @onSuccessfullyVerified="onSuccessfullyVerified"
              v-if="!userDetail.emailVerifiedAt"
              :userId="userDetail._id"
            />
          </div>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
            this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="contactNumber">Contact Number</label>
          <p id="contactNumber">{{ userDetail.contactNumber }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'doctor' ||
            this.userDetail.userType === 'nurse'
        "
      >
        <div class="w-full m-5">
          <label for="dateOfBirth">Date Of Birth</label>
          <p id="dateOfBirth">{{ userDetail.dateOfBirth | date_formatter }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="preferredName">Preferred Name</label>
          <p id="preferredName">{{ userDetail.preferredName }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="ahpraRegistrationNumber">Ahpra Registration Number</label>
          <p id="ahpraRegistrationNumber">
            {{ userDetail.ahpraRegistrationNumber }}
          </p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="ahpraExiryDate">Ahpra Exiry Date</label>
          <p id="ahpraExiryDate">
            {{ userDetail.ahpraExiryDate | date_formatter }}
          </p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="indemnityInsuranceProvider"
            >Indemnity Insurance Provider</label
          >
          <p id="indemnityInsuranceProvider">
            {{ userDetail.indemnityInsuranceProvider }}
          </p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="indemnityInsuranceNumber"
            >Indemnity Insurance Number</label
          >
          <p id="indemnityInsuranceNumber">
            {{ userDetail.indemnityInsuranceNumber }}
          </p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'nurse' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="indemnityInsuranceExpiry"
            >Indemnity Insurance Expiry</label
          >
          <p id="indemnityInsuranceExpiry">
            {{ userDetail.indemnityInsuranceExpiry | date_formatter }}
          </p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full m-5">
          <label for="companyName">Company Name</label>
          <p id="companyName">{{ userDetail.companyName }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <label for="clinicName">Clinic Name</label>
          <p id="clinicName">{{ userDetail.clinicName }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full m-5">
          <label for="franchiseBusinessName">Franchise Business Name</label>
          <p id="franchiseBusinessName">
            {{ userDetail.franchiseBusinessName }}
          </p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="abn">ABN</label>
          <p id="abn">{{ userDetail.abn }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic' ||
            this.userDetail.userType === 'doctor'
        "
      >
        <div class="w-full m-5">
          <label for="address">Address</label>
          <p id="address">
            {{ userDetail.address ? userDetail.address.displayAddress : "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full m-5">
          <label for="postalAddress">Postal Address</label>
          <p id="postalAddress">{{ userDetail.postalAddress }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full m-5">
          <label for="bestContactName">Best Contact Name</label>
          <p id="bestContactName">{{ userDetail.bestContactName }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType === 'franchise' ||
            this.userDetail.userType === 'clinic'
        "
      >
        <div class="w-full m-5">
          <label for="bestContactEmail">Best Contact Email</label>
          <p id="bestContactEmail">{{ userDetail.bestContactEmail }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <label for="billingEmail">Billing Email</label>
          <p id="billingEmail">{{ userDetail.billingEmail }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full m-5">
          <label for="invoiceEmail">Invoice Email</label>
          <p id="invoiceEmail">{{ userDetail.invoiceEmail }}</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'franchise'"
      >
        <div class="w-full m-5">
          <label for="wantCentralisebilling">Want Centralise Billing</label>
          <p id="wantCentralisebilling">
            {{ userDetail.wantCentralisebilling ? "Yes" : "No" }}
          </p>
        </div>
      </vs-col>

      <vs-col
        v-if="userDetail.userType === 'doctor'"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
      >
        <div class="w-full m-5">
          <label for="prescriberNumber">Prescriber Number</label>
          <p id="prescriberNumber">{{ userDetail.prescriberNumber }}</p>
        </div>
      </vs-col>

      <vs-col
        v-if="userDetail.userType === 'nurse'"
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
      >
        <div class="w-full m-5">
          <label for="nurseCosmeticExperience">Cosmetic Experience</label>
          <p id="nurseCosmeticExperience">
            {{ userDetail.nurseCosmeticExperience || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">User Status</label>
          <p v-if="userDetail.status === 'Pending_Approval'" class="text-sm">
            Pending Approval
          </p>
          <p v-else class="text-sm">{{ userDetail.status }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">Admin Approval</label>
          <p v-if="userDetail.hasApprovedByAdmin" class="text-sm">Approved</p>
          <p v-else class="text-sm">Unapproved</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'doctor'"
      >
        <div class="w-full m-5">
          <label for="availabitityStatus">Available Status</label>
          <p v-if="userDetail.hasSetupAvailabitityStatus" class="text-sm">
            {{ userDetail.availabitityStatus }}
          </p>
          <p v-else class="text-sm">Not Set Yet</p>
        </div>
      </vs-col>
    </vs-row>

    <vs-row
      v-if="userDetail.userType === 'nurse'"
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="instagram">Instagram</label>
          <p id="instagram">{{ userDetail.instagram || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="facebook">Facebook</label>
          <p id="facebook">{{ userDetail.facebook || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="website">Website</label>
          <p id="website">{{ userDetail.website || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="clinicName">Clinic Name</label>
          <p id="clinicName">{{ userDetail.clinicName || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="clinicAddress1">Clinic Address 1</label>
          <p id="clinicAddress1">{{ userDetail.clinicAddress1 || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="city">City</label>
          <p id="city">{{ userDetail.city || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="stateOrRegion">State/Region</label>
          <p id="stateOrRegion">{{ userDetail.stateOrRegion || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="photoId">Photo ID</label>
          <a
            v-if="userDetail.photoId"
            :href="userDetail.photoId"
            target="_blank"
          >
            <p id="photoId">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="restrictionImposed"
            >Have you ever had any restrictions imposed on your ability to
            practice?</label
          >
          <p id="restrictionImposed">
            {{ userDetail.restrictionImposed || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="aggreementOnAhpraRegistrationChanged"
            >Change in registration (Do you agree to tell us within 48hrs of any
            change in your registration status?)</label
          >
          <p id="aggreementOnAhpraRegistrationChanged">
            {{ userDetail.aggreementOnAhpraRegistrationChanged || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="aggreementOnIndemnityRegistrationChanged"
            >Change in insurance (Do you agree to tell us within 48hrs of any
            change in your insurance?)</label
          >
          <p id="aggreementOnIndemnityRegistrationChanged">
            {{ userDetail.aggreementOnIndemnityRegistrationChanged || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="rnOrEn"
            >Are you an RN or an EN (If an EN, you must read the guidelines on
            supervision and acknowledge the requirements involved. Contact us
            for more detail if unsure.)</label
          >
          <p id="rnOrEn">{{ userDetail.rnOrEn || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="expericedYearInRnOrEn"
            >How many years of experience have you had as an RN or an EN?</label
          >
          <p id="expericedYearInRnOrEn">
            {{ userDetail.expericedYearInRnOrEn || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="areasForRnOrEn">In what areas/fields?</label>
          <p id="areasForRnOrEn">{{ userDetail.areasForRnOrEn || "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cosmeticInjectorExperienceYears"
            >For how many years have you been a cosmetic injector?</label
          >
          <p id="cosmeticInjectorExperienceYears">
            {{ userDetail.cosmeticInjectorExperienceYears || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cosmeticInjectableCourses"
            >Please list any courses in cosmetic injectables that you have
            completed.</label
          >
          <p id="cosmeticInjectableCourses">
            {{ userDetail.cosmeticInjectableCourses || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="trainingCertificate">Training Certificate</label>
          <a
            v-if="userDetail.trainingCertificate"
            :href="userDetail.trainingCertificate"
            target="_blank"
          >
            <p id="trainingCertificate">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="additionalTeachingInInjectables"
            >Please list any other teaching that you have had in injectables.
            (For instance, from another nurse? A Doctor? On-the-job training?
            From a chain clinic? Please provide some detail (names, locations,
            durations))</label
          >
          <p id="additionalTeachingInInjectables">
            {{ userDetail.additionalTeachingInInjectables || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="averageHourInCosmeticPracticePerWeek"
            >On average, how many hours of in-clinic cosmetic practice per week
            do you currently do?</label
          >
          <p id="averageHourInCosmeticPracticePerWeek">
            {{ userDetail.averageHourInCosmeticPracticePerWeek || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="planForAverageHourInCosmeticPracticePerWeek"
            >How many hours per week of in-clinic practice do you plan to
            do?</label
          >
          <p id="planForAverageHourInCosmeticPracticePerWeek">
            {{
              userDetail.planForAverageHourInCosmeticPracticePerWeek || "N/A"
            }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="numberOfPatientsTreated"
            >Roughly, how many patients do you think you have treated?</label
          >
          <p id="numberOfPatientsTreated">
            {{ userDetail.numberOfPatientsTreated || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="confidentAreasForToxinTreatment"
            >What are your confident areas for toxin treatment?</label
          >
          <p id="confidentAreasForToxinTreatment">
            {{ confidentAreasForToxin }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="confidentAreasForFillerTreatment"
            >What are your confident areas for filler treatment?</label
          >
          <p id="confidentAreasForFillerTreatment">
            {{ confidentAreasForFiller }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="areasNotConfidentIn"
            >Are there any areas that you are not confident in?</label
          >
          <p id="areasNotConfidentIn">
            {{ userDetail.areasNotConfidentIn || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="additionalAreaOfPractice"
            >Are there any other areas, products or techniques are part of your
            practice?</label
          >
          <p id="additionalAreaOfPractice">
            {{ userDetail.additionalAreaOfPractice || "N/A" }}
          </p>
        </div>
      </vs-col>

      <!--      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label for="cv">CV</label>
                <a v-if="userDetail.cv" :href="userDetail.cv" target="_blank">
                  <p id="cv">View</p>
                </a>
                <p v-else>N/A</p>
              </div>
            </vs-col>-->

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="hasCompletedHyalaseTraningInLastTwelveMonth"
            >Have you completed hyalase training in the last 12 months?</label
          >
          <p id="hasCompletedHyalaseTraningInLastTwelveMonth">
            {{
              userDetail.hasCompletedHyalaseTraningInLastTwelveMonth || "N/A"
            }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="hyalaseOrComplicationManagementCertificate"
            >Relevant certificates for hyalase/complication management</label
          >
          <a
            v-if="userDetail.hyalaseOrComplicationManagementCertificate"
            :href="userDetail.hyalaseOrComplicationManagementCertificate"
            target="_blank"
          >
            <p id="hyalaseOrComplicationManagementCertificate">View</p>
          </a>
          <p v-else>N/A</p>
        </div>
      </vs-col>

      <!--
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label for="hasCompletedBasicLifeSupportTrainingInLastTwelveMonth"
                  >Have you completed Basic Life Support training (BLS) in the last 12
                  months?</label
                >
                <p id="hasCompletedBasicLifeSupportTrainingInLastTwelveMonth">
                  {{
                    userDetail.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth ||
                      "N/A"
                  }}
                </p>
              </div>
            </vs-col>
      -->

      <!--      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label for="blsCertificate">BLS Certificate</label>
                <a
                  v-if="userDetail.blsCertificate"
                  :href="userDetail.blsCertificate"
                  target="_blank"
                >
                  <p id="blsCertificate">View</p>
                </a>
                <p v-else>N/A</p>
              </div>
            </vs-col>-->

      <!--      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label for="hasImmunityToHepatitisB"
                  >Has immunity to Hepatitis B</label
                >
                <p id="hasImmunityToHepatitisB">
                  {{ userDetail.hasImmunityToHepatitisB || "N/A" }}
                </p>
              </div>
            </vs-col>-->

      <!--      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <label for="vaccinationCertificate">Vaccination Certificate</label>
                <a
                  v-if="userDetail.vaccinationCertificate"
                  :href="userDetail.vaccinationCertificate"
                  target="_blank"
                >
                  <p id="vaccinationCertificate">View</p>
                </a>
                <p v-else>N/A</p>
              </div>
            </vs-col>-->

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="hasReadClinicalStandardsDocuments"
            >Have you read and agree to adhere to the Fresh Clinics "Clinical
            Standards" document?</label
          >
          <p id="hasReadClinicalStandardsDocuments">
            {{ userDetail.hasReadClinicalStandardsDocuments || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="hasConfirmedDeclarations"
            >I confirm that I have made a true and accurate declaration.</label
          >
          <p id="hasConfirmedDeclarations">
            {{ userDetail.hasConfirmedDeclarations || "N/A" }}
          </p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="canSendEmail">Are you happy for us to email you?</label>
          <p id="canSendEmail">{{ userDetail.canSendEmail || "N/A" }}</p>
        </div>
      </vs-col>
    </vs-row>

    <div
      v-if="
        userDetail.userType === 'doctor' &&
          check_has_permission('viewDoctorBankDetails')
      "
    >
      <vs-divider />
      <div style="display: flex; justify-content: space-between;">
        <h4>Bank Detail</h4>
        <vs-button
          v-if="
            userDetail.financialDetails &&
              userDetail.financialDetails.accountHolderName &&
              ($store.state.AppActiveUser.userType === 'admin' ||
                $store.state.AppActiveUser.userType === 'superAdmin')
          "
          color="danger"
          type="filled"
          ref="loadableButton"
          id="delete-bank-details"
          class="vs-con-loading__container"
          @click="popUpDelete = true"
          :disabled="disabled"
          >Remove Details</vs-button
        >
      </div>

      <vs-popup :active.sync="popUpDelete" title="Confirmation">
        <p align="center">
          Are you sure you want to remove payment details from this account?
        </p>
        <br />
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          vs-mt="6"
        >
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5" align="right">
              <vs-button
                size="lg"
                color="primary"
                @click="removeBankDetails(userDetail._id)"
                >Yes, Remove</vs-button
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button size="lg" color="danger" @click="popUpDelete = false"
                >Cancel</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </vs-popup>

      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="accountHolderName">Account Holder Name</label>
            <p id="accountHolderName">
              {{
                userDetail.financialDetails &&
                userDetail.financialDetails.accountHolderName
                  ? userDetail.financialDetails.accountHolderName
                  : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="bsb">BSB</label>
            <p id="bsb">
              {{
                userDetail.financialDetails && userDetail.financialDetails.bsb
                  ? userDetail.financialDetails.bsb
                  : "N/A"
              }}
            </p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="accountNumber">Account Number</label>
            <p id="accountNumber">
              {{
                userDetail.financialDetails &&
                userDetail.financialDetails.accountNumber
                  ? userDetail.financialDetails.accountNumber
                  : "N/A"
              }}
            </p>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
import VerifyEmail from "@/views/general-component/VerifyEmail";
import { mapActions } from "vuex";

export default {
  name: "ProfileDetail",
  components: {
    VerifyEmail
  },
  data: () => ({
    defaultPP: require("@/assets/images/profile/default.png"),
    confidentAreasForToxin: "",
    confidentAreasForFiller: "",
    userDetail: {},
    disabled: false,
    popUpDelete: false
  }),
  methods: {
    ...mapActions("general", ["removeUserBankDetails"]),
    removeBankDetails(userId) {
      this.popUpDelete = false;
      this.disabled = true;
      this.$vs.loading({
        background: "danger",
        color: "#fff",
        container: "#delete-bank-details",
        scale: 0.45
      });

      this.removeUserBankDetails({ userId, type: "doctor" })
        .then(response => {
          this.popUpDelete = false;
          this.disabled = false;
          this.$vs.loading.close("#delete-bank-details > .con-vs-loading");
          this.userDetail = response.data.data;
        })
        .catch(() => {
          this.popUpDelete = false;
          this.disabled = false;
          this.$vs.loading.close("#delete-bank-details > .con-vs-loading");
          this.$vs.notify({
            title: "Failed",
            text: "Oops! something went wrong",
            color: "danger"
          });
        });
    },
    onSuccessfullyVerified(verifiedAt) {
      this.userDetail.emailVerifiedAt = verifiedAt;
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    goBackToList() {
      const ut = `${this.userDetail.userType}s`;
      switch (this.$store.state.AppActiveUser.userType) {
        case "superAdmin":
          this.$router.push("/super-admin/" + ut);
          break;
        case "admin":
          this.$router.push("/admin/" + ut);
          break;
        case "franchise":
          this.$router.push("/franchise/" + ut);
          break;
        case "clinic":
          this.$router.push("/clinic/" + ut);
          break;
      }
    }
  },
  async created() {
    this.$vs.loading();
    this.$store
      .dispatch(
        "general/getUserDetailById",
        this.$route.params.doctorId
          ? this.$route.params.doctorId
          : this.$route.params.nurseId
      )
      .then(response => {
        if (response.data.data.permissions) {
          this.$store.commit(
            "UPDATE_USER_PERMISSION",
            response.data.data.permissions
          );
        }
        this.userDetail = response.data.data;

        if (this.userDetail.userType === "nurse") {
          if (this.userDetail.confidentAreasForToxinTreatment) {
            let obj = this.userDetail.confidentAreasForToxinTreatment;

            let keys = Object.keys(obj);

            let filtered = keys.filter(function(key) {
              return obj[key];
            });

            if (filtered && filtered.length > 0)
              this.confidentAreasForToxin = filtered.join(", ");
            else this.confidentAreasForToxin = "N/A";
          } else {
            this.confidentAreasForToxin = "N/A";
          }
        }

        if (this.userDetail.userType === "nurse") {
          if (this.userDetail.confidentAreasForFillerTreatment) {
            let obj = this.userDetail.confidentAreasForFillerTreatment;

            let keys = Object.keys(obj);

            let filtered = keys.filter(function(key) {
              return obj[key];
            });

            if (filtered && filtered.length > 0)
              this.confidentAreasForFiller = filtered.join(", ");
            else this.confidentAreasForFiller = "N/A";
          } else {
            this.confidentAreasForFiller = "N/A";
          }
        }

        this.$vs.loading.close();
      })
      .catch(err => {
        this.$vs.loading.close();
      });
  }
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}

label {
  font-size: 0.8rem;
}

div.vs-con-textarea {
  margin-top: 0 !important;
}
</style>
